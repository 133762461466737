.App {
  text-align: center;
}

a {
  color: black;
  text-decoration: none;
}

a b {
  text-transform: capitalize;
  padding-right: 10px;
}

a svg {
  color: #999;
}